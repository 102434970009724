// package imports
import React from "react";
// Material UI Imports
import { Divider, Grid, ListItem, Typography } from "@material-ui/core";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";

export default function CheckoutItem(props) {
  // State and Props
  const [price, setPrice] = React.useState();
  const { item } = props;

  // Change Handlers
  const handlePriceChange = (event, value) => {
    setPrice((prevPrice) => {
      props.onChange(props.index, event, value);
      return value;
    });
  };

  return (
    <>
      <ListItem>
        <Grid container alignItems="center" justify="space-between">
          <Grid item xs={8}>
            <Typography>{item.item}</Typography>
          </Grid>
          <Grid item xs={4}>
            <CurrencyTextField
              variant="standard"
              value={price}
              currencySymbol="$"
              minimumValue="0"
              outputFormat="number"
              decimalCharacter="."
              digitGroupSeparator=","
              onChange={handlePriceChange}
              inputProps={{
                inputMode: "decimal",
              }}
            />
          </Grid>
        </Grid>
      </ListItem>
      <Divider />
    </>
  );
}
