export const userBySubId = /* GraphQL */ `
  query UserBySubId(
    $subID: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userBySubID(
      subID: $subID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        subID
        name
        lists {
          items {
            id
            list {
              id
              title
              owner {
                id
                name
              }
              users {
                items {
                  user {
                    id
                    name
                  }
                }
                nextToken
              }
              groups
            }
          }
          nextToken
        }
        ownedLists {
          items {
            id
            title
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const getList = /* GraphQL */ `
  query GetList($id: ID!) {
    getList(id: $id) {
      id
      title
      owner {
        id
        subID
      }
      users {
        items {
          id
          userID
          listID
          user {
            id
            name
          }
        }
        nextToken
      }
      groups
      items(limit: 50) {
        items {
          id
          item
          category
          split
        }
        nextToken
      }
    }
  }
`;
