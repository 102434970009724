import React from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  Backdrop,
  Button,
  Chip,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Grid,
  IconButton,
  Modal,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PersonIcon from "@material-ui/icons/Person";
import GroupIcon from "@material-ui/icons/Group";
import { useSpring, animated } from "react-spring/web.cjs"; // web.cjs is required for IE 11 support

// Custom Imports
import TabPanel from "./TabPanel";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  tabs: {
    flexGrow: 1,
    width: "100%",
    margin: "0",
  },
}));

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

export default function AddItemModal(props) {
  const classes = useStyles();

  // State and Props
  const [tabValue, setTabValue] = React.useState(0);
  const [expandedSplit, setExpandedSplit] = React.useState(true);
  const [chipStates, setChipStates] = React.useState([]);
  const [chipsError, setChipsError] = React.useState(false);
  const [category, setCategory] = React.useState("");
  const {
    open,
    setOpen,
    newItem,
    newItemError,
    users,
    handleAddItemChange,
    onAdd,
  } = props;

  // Change Handlers
  const handleSplitExpansion = (object, expanded) => {
    setExpandedSplit(expanded);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setChipStates(new Map());
  };

  function handleChipClick(index) {
    setChipsError(false);
    setChipStates((prevChipStates) => {
      let newChipStates = [...prevChipStates];
      newChipStates[index] = !prevChipStates[index];
      return newChipStates;
    });
  }

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Functions
  function onCustomAdd() {
    let noneSelected = true;
    let split = [];
    users.forEach((user, index) => {
      if (chipStates[index]) {
        split.push(user.id);
        noneSelected = false;
      }
    });

    if (noneSelected) {
      setChipsError(true);
      setExpandedSplit(true);
    } else {
      const newGroceryItem = {
        item: newItem,
        split: split,
        category: category,
      };
      onAdd(newGroceryItem);
    }
  }

  React.useEffect(() => {
    let newChipsStates = [];
    users.forEach(() => {
      newChipsStates.push(true);
    });
    setChipStates(newChipsStates);
  }, [users]);

  return (
    <Modal
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            spacing={1}
          >
            <Grid container item xs={12}>
              <Grid item xs={1}>
                <IconButton onClick={() => handleClose()}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={newItemError}
                label="Add Item"
                fullWidth={true}
                margin="dense"
                value={newItem}
                onChange={handleAddItemChange}
              />
            </Grid>
            <Grid item xs={12}>
              <ExpansionPanel
                style={{ width: "100%" }}
                defaultExpanded={true}
                onChange={handleSplitExpansion}
                expanded={expandedSplit}
              >
                <ExpansionPanelSummary
                  classes={{
                    expandIcon: classes.expandIcon,
                    expanded: classes.expanded,
                  }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-label="Expand"
                  aria-controls="additional-actions1-content"
                  id="additional-actions1-header"
                >
                  <Typography>Split</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div className={classes.tabs}>
                    <AppBar position="static">
                      <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        variant="fullWidth"
                        centered
                      >
                        <Tab label="People" />
                        <Tab label="Groups" />
                      </Tabs>
                    </AppBar>
                    <TabPanel value={tabValue} index={0}>
                      <Grid
                        container
                        direction="column"
                        justify="space-between"
                        alignItems="center"
                        spacing={1}
                      >
                        {users.map((user, index) => {
                          return (
                            <Grid key={user.id} item>
                              <Chip
                                icon={<PersonIcon />}
                                label={user.name}
                                clickable
                                color={chipsError ? "secondary" : "primary"}
                                onClick={() => handleChipClick(index)}
                                variant={
                                  chipStates[index] ? "default" : "outlined"
                                }
                              />
                            </Grid>
                          );
                        })}
                      </Grid>
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                      <Grid
                        container
                        direction="column"
                        justify="space-between"
                        alignItems="center"
                        spacing={1}
                      >
                        <Grid item>
                          <Chip
                            icon={<GroupIcon />}
                            label="Meat Eaters"
                            clickable
                            color="primary"
                            onClick={() => handleChipClick()}
                            variant={true ? "outlined" : "default"}
                          />
                        </Grid>
                        {/* <Grid item>
                        <Chip
                          label="Alan"
                          clickable
                          color="primary"
                          onClick={() => handleClick("asd")}
                          variant={clicked ? "outlined" : "default"}
                        />
                      </Grid>
                      <Grid item>
                        <Chip
                          label="Kenneth"
                          clickable
                          color="primary"
                          onClick={() => handleClick("label")}
                          variant={clicked ? "outlined" : "default"}
                        />
                      </Grid> */}
                      </Grid>
                    </TabPanel>
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Grid>
            <Grid item xs={12}>
              <ExpansionPanel style={{ width: "100%" }} defaultExpanded={true}>
                <ExpansionPanelSummary
                  classes={{
                    expandIcon: classes.expandIcon,
                    expanded: classes.expanded,
                  }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-label="Expand"
                  aria-controls="additional-actions1-content"
                  id="additional-actions1-header"
                >
                  <Typography>Category</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <TextField
                    label="Category"
                    fullWidth={true}
                    margin="dense"
                    value={category}
                    onChange={handleCategoryChange}
                  />
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Grid>
            <Grid container item xs={12} justify="center">
              <Grid item>
                <Button variant="contained" onClick={() => onCustomAdd()}>
                  Add Item
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Fade>
    </Modal>
  );
}
