import React from "react";
// Material UI Imports
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Alert from '@material-ui/lab/Alert';
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
// Amplify Imports
import { API, graphqlOperation } from "aws-amplify";
import {
  createList,
  createUserList,
  createListItem,
  deleteListItem,
  shareList,
} from "../graphql/mutations";
import { getList, userBySubId } from "../graphql/customQueries";
import { getListItem } from "../graphql/queries";
import { onUpdateList } from "../graphql/subscriptions";
import {
  onCreateListItem,
  onDeleteListItem,
  onUpdateListItem,
  onCreateUserList,
} from "../graphql/customSubscriptions";
// Other Imports
import { useHistory } from "react-router-dom";
// Custom Imports
import List from "./List";
import AddItemModal from "./AddItemModal";
import CreateNewListModal from "./CreateNewListModal";
import ShareListModal from "./ShareListModal";

// Style Classes
const useStyles = makeStyles((theme) => ({
  alert: {
    position: "sticky",
    width: "100%"
  },
  spinner: {
    position: "fixed",
    bottom: "50%",
    width: "100%",
  },
  titleFormControl: {
    width: "100%",
  },
  titleSelect: {
    "&:before": {
      borderColor: "transparent",
    },
    fontSize: "200%",
    fontFamily: "Arial, Helvetica, sans-serif",
  },
  resize: {
    fontSize: 50,
  },
  list: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    marginBottom: "30%",
  },
  category: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  checkoutButtonContainer: {
    position: "fixed",
    bottom: "10%",
  },
  checkoutButton: {
    boxShadow: "16px rgba(0,0,0,0), 20px rgba(0, 0, 0,0.2)",
  },
  checkoutButtonBg: {
    animation: `$myEffect 3000ms infinite ${theme.transitions.easing.easeInOut}`,
  },
  "@keyframes myEffect": {
    "0%": {
      transform: "scale(1, 1)",
    },
    "50%": {
      opacity: 0.3,
    },
    "100%": {
      transform: "scale(1.1)",
      opacity: 0,
    },
  },
}));

function GroceryList(props) {
  // Styles
  const classes = useStyles();

  let history = useHistory();

  // State
  const [loading, setLoading] = React.useState(true);
  const [alertSeverity, setAlertSeverity] = React.useState("");
  const [alertMessage, setAlertMessage] = React.useState("");
  const [newItem, setNewItem] = React.useState("");
  const [newItemError, setNewItemError] = React.useState(false);
  const [checkedItems, setCheckedItems] = React.useState(new Map());
  const [expandedPanels, setExpandedPanels] = React.useState(new Map());
  const [groceryItems, setGroceryItems] = React.useState([]);
  const [sortedGroceryItems, setSortedGroceryItems] = React.useState(new Map());
  const [open, setOpen] = React.useState(false);
  const [createNewListOpen, setCreateNewListOpen] = React.useState(false);
  const [shareListOpen, setShareListOpen] = React.useState(false);

  const [userID, setUserID] = React.useState("");
  const [userLists, setUserLists] = React.useState([]);
  const [currentListID, setCurrentListID] = React.useState("");
  const [users, setUsers] = React.useState([]);
  const [userGroups, setUserGroups] = React.useState([]);
  const [isOwner, setIsOwner] = React.useState(false);
  // eslint-disable-next-line
  const [currentSubs, setCurrentSubs] = React.useState([]);

  // Change Handlers
  const handleListChange = (event) => {
    if (event.target.value === "Add") {
      setCreateNewListOpen(true);
    } else {
      setCurrentListID(event.target.value);
    }
  };

  const handleAddItemChange = (event) => {
    setNewItem(event.target.value);
  };

  const handleChecked = (e) => {
    const isChecked = e.target.checked;
    const id = e.target.value;
    if (isChecked) {
      setCheckedItems((prevCheckedItems) => {
        prevCheckedItems.set(id, isChecked);
        return new Map(prevCheckedItems);
      });
    } else {
      setCheckedItems((prevCheckedItems) => {
        prevCheckedItems.delete(id);
        return new Map(prevCheckedItems);
      });
    }
  };

  const handleExpanded = (panelID) => {
    setExpandedPanels((prevExpandedPanels) => {
      prevExpandedPanels.set(panelID, !prevExpandedPanels.get(panelID));
      return new Map(prevExpandedPanels);
    });
  };

  function keyPress(e) {
    if (e.keyCode === 13) {
      onAdd();
    }
  }

  async function onAdd(newGroceryItem) {
    // Check if the item is already on the list
    if (groceryItems.some((item) => item.item === newItem)) {
      setNewItemError(true);
    } else {
      setOpen(false);
      setNewItemError(false);
      setNewItem("");

      if (!newGroceryItem) {
        // List of all the users
        let allUsers = [];
        users.forEach((user) => allUsers.push(user.id));

        newGroceryItem = {
          item: newItem,
          split: allUsers,
          category: "",
        };
      }

      newGroceryItem["listItemListId"] = currentListID;
      await API.graphql(
        graphqlOperation(createListItem, { input: newGroceryItem })
      );
    }
  }

  function onCustomAdd() {
    setOpen(true);
  }

  async function onDelete(item) {
    expandedPanels.delete(item.id);
    await API.graphql(
      graphqlOperation(deleteListItem, {
        input: { id: item.id },
      })
    );
  }

  function onShare() {
    setShareListOpen(true);
  }

  const onCheckOut = async () => {
    let promises = [];
    console.log(
      checkedItems.forEach((value, itemID) => {
        promises.push(
          API.graphql(graphqlOperation(getListItem, { id: itemID }))
        );
      })
    );

    let items = await Promise.all(promises);
    items = items.map((item) => item.data.getListItem);
    history.push({
      pathname: "/Checkout",
      state: { items: items, users: users },
    });
  };

  // Functions
  async function createNewList(title) {
    const newList = {
      title: title,
      listOwnerId: userID,
    };
    const createListResponse = await API.graphql(
      graphqlOperation(createList, { input: newList })
    );
    const list = createListResponse.data.createList;

    const newUserList = {
      userID: userID,
      listID: list.id,
    };

    await API.graphql(graphqlOperation(createUserList, { input: newUserList }));

    const user = await API.graphql(
      graphqlOperation(userBySubId, { subID: props.userSubID })
    );
    console.log(user);
    let lists = user.data.userBySubID.items[0].lists.items;
    lists = lists.map((item) => item.list);
    setUserLists(lists);
    setCreateNewListOpen(false);
    setCurrentListID(list.id);
  }

  async function addUserToList(newUserID) {
    const shareListResult = await API.graphql(
      graphqlOperation(shareList, {
        listID: currentListID,
        newUserID: newUserID,
      })
    );
    let response = shareListResult.data.shareList;
    response = response.substring(1, response.length - 1);;
    var json = {};
    response.split(', ').map(function (each) {
      return each.split('=');
    }).forEach(function (pair) {
      json[pair[0]] = pair[1];
    });

    const statusCode = json.statusCode;
    let data = "";

    if (Math.floor(statusCode / 100) === 2) {
      console.log("status code success");
      setAlertSeverity("success");
      data = json.body;
    } else if (Math.floor(statusCode / 100) === 4) {
      setAlertSeverity("error");
      data = json.data;
    }
    setAlertMessage(data);
    setShareListOpen(false);

    setTimeout(resetAlert, 3000);

  }

  function resetAlert() {
    setAlertSeverity("");
    setAlertMessage("");
  }

  // Set Initial User Values
  React.useEffect(() => {
    async function handleGetUserBySubID() {
      // Get current user's lists
      const user = await API.graphql(
        graphqlOperation(userBySubId, { subID: props.userSubID })
      );
      let lists = user.data.userBySubID.items[0].lists.items;
      lists = lists.map((item) => item.list);
      setUserID(user.data.userBySubID.items[0].id);
      setUserLists(lists);
      // TODO: Store nextToken for User Lists (maybe: user.data.userBySubID.items[0].lists.nextToken) for lazy loading lists

      setCurrentListID(lists[0].id);
    }

    handleGetUserBySubID();
  }, [props.userSubID]);

  // Set Grocery Items and Subscriptions on List Change
  React.useEffect(() => {
    async function handleSetAndSubscribeGroceryItems() {
      setLoading(true);
      const newListID = currentListID;
      // Set Grocery Items;
      const list = await API.graphql(
        graphqlOperation(getList, { id: newListID })
      );
      setGroceryItems(list.data.getList.items.items);
      // TODO: Store nextToken for grocery items(maybe: list.data.getList.items.nextToken) for lazy loading items
      // TODO: Sort items by category for fetch

      // Set Users
      setUsers(list.data.getList.users.items.map((item) => item.user));
      setUserGroups(list.data.getList.groups || []);
      setIsOwner(list.data.getList.owner.id === userID);

      setCurrentSubs((prevCurrentSubs) => {
        // Unsubscribe from all previous subscriptions (maybe)
        prevCurrentSubs.forEach((sub) => {
          sub.unsubscribe();
        });

        // Subscribe to new listID
        let subscriptions = [];
        // Subscribe to creation of a grocery item
        subscriptions.push(
          API.graphql(
            graphqlOperation(onCreateListItem, {
              listItemListId: newListID,
            })
          ).subscribe({
            next: (listItemData) => {
              console.log("OnCreateListItem");
              setGroceryItems((prevGroceryItems) => {
                if (prevGroceryItems.filter(item => item.id === listItemData.value.data.onCreateListItem.id).length === 0) {
                  let newGroceryItems = [...prevGroceryItems];
                  newGroceryItems.push(listItemData.value.data.onCreateListItem);
                  return newGroceryItems;
                } else {
                  return prevGroceryItems;
                }
              });
            },
          })
        );

        // Subscribe to deletion of grocery item
        subscriptions.push(
          API.graphql(
            graphqlOperation(onDeleteListItem, {
              listItemListId: newListID,
            })
          ).subscribe({
            next: (listItemData) => {
              setGroceryItems((prevGroceryItems) => {
                let newGroceryItems = [];
                prevGroceryItems.forEach((value) => {
                  if (
                    value.id !== listItemData.value.data.onDeleteListItem.id
                  ) {
                    newGroceryItems.push(value);
                  }
                });
                return newGroceryItems;
              });
            },
          })
        );

        // Subscribe to update of grocery item
        subscriptions.push(
          API.graphql(
            graphqlOperation(onUpdateListItem, {
              listItemListId: newListID,
            })
          ).subscribe({
            next: (listItemData) => {
              setGroceryItems((prevGroceryItems) => {
                let newGroceryItems = [];
                prevGroceryItems.forEach((value) => {
                  if (
                    value.id === listItemData.value.data.onUpdateListItem.id
                  ) {
                    value = listItemData.value.data.onUpdateListItem;
                  }
                  newGroceryItems.push(value);
                });
                return newGroceryItems;
              });
            },
          })
        );

        // Subscribe to update of list (Group Creation)
        subscriptions.push(
          API.graphql(
            graphqlOperation(onUpdateList, {
              id: newListID,
            })
          ).subscribe({
            next: (listData) => {
              setUserGroups(listData.value.data.onUpdateList.groups);
            },
          })
        );

        // Subscribe to creation of UserLists (Adding Users to List)
        subscriptions.push(
          API.graphql(
            graphqlOperation(onCreateUserList, {
              listID: newListID,
            })
          ).subscribe({
            next: async (userListData) => {
              const list = await API.graphql(
                graphqlOperation(getList, {
                  id: userListData.value.data.onCreateUserList.list.id,
                })
              );
              setUsers(list.data.getList.users.items.map((item) => item.user));
            },
          })
        );

        setLoading(false);
        return subscriptions;
      });
    }

    if (currentListID !== "") {
      console.log("handleSetAndSubscribeGroceryItems: " + currentListID);
      handleSetAndSubscribeGroceryItems();
    }
  }, [currentListID, userID]);

  // Sort items by category
  React.useEffect(() => {
    let categories = new Set();
    groceryItems.forEach((groceryItem) => {
      categories.add(groceryItem.category);
    });

    let sortedCategories = Array.from(categories).sort();
    const newSortedGroceryItems = new Map();
    sortedCategories.forEach((category) => {
      newSortedGroceryItems.set(category, []);
    });

    groceryItems.forEach((groceryItem) => {
      newSortedGroceryItems.get(groceryItem.category).push(groceryItem);
    });

    setSortedGroceryItems(newSortedGroceryItems);
  }, [groceryItems]);

  return (
    <div>
      <Grid container>
        {/* Side Spacer */}
        <Grid item sm={false} md={4} />
        {/* ************************ Main Content ************************ */}
        <Grid container item xs={12} md={4} justify="center">
          <Alert severity={alertSeverity} className={classes.alert}>{alertMessage}</Alert>
          {/* Select List */}
          <Grid container item xs={12} justify="center" alignItems="center">
            <Grid item xs={10}>
              <FormControl className={classes.titleFormControl}>
                <Select
                  native
                  value={currentListID}
                  onChange={handleListChange}
                  className={classes.titleSelect}
                >
                  {userLists.map((list) => {
                    return (
                      <option key={list.id} value={list.id}>
                        {list.title}
                      </option>
                    );
                  })}
                  <option value={"Add"}>+ Create New List</option>
                </Select>
              </FormControl>
            </Grid>
            {/* ShareList Button */}
            <Grid item xs={1}>
              {isOwner &&
                <IconButton onClick={() => onShare()}>
                  <PersonAddIcon />
                </IconButton>
              }
            </Grid>
          </Grid>
          {loading ? (
            <CircularProgress className={classes.spinner} />
          ) : (
              <>
                {/* Add Item */}
                <Grid container item xs={8} alignItems="center">
                  <Grid item xs={11}>
                    <TextField
                      error={newItemError}
                      helperText={
                        newItemError && "That item is already on the list"
                      }
                      label="Add Item"
                      variant="outlined"
                      fullWidth={true}
                      margin="dense"
                      value={newItem}
                      onChange={handleAddItemChange}
                      onKeyDown={keyPress}
                      InputProps={{
                        endAdornment: newItem !== "" && (
                          <InputAdornment position="end">
                            <IconButton onClick={() => onCustomAdd()}>
                              <EditIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton onClick={() => onAdd()}>
                      <AddIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                {/* List of Items */}
                <Grid item container className={classes.list}>
                  {Array.from(sortedGroceryItems).map(([key, value]) => (
                    <Grid key={key} item className={classes.category}>
                      <Typography>
                        {key === "" ? "Uncategorized" : key}
                      </Typography>
                      <List
                        currentListID={currentListID}
                        groceryItems={value}
                        users={users}
                        userGroups={userGroups}
                        checkedItems={checkedItems}
                        handleChecked={handleChecked}
                        expandedPanels={expandedPanels}
                        handleExpanded={handleExpanded}
                        onDelete={onDelete}
                      />
                    </Grid>
                  ))}
                </Grid>
              </>
            )}
        </Grid>
        {/* ************************ End Main Content ************************ */}
        {/* Side Spacer */}
        <Grid item sm={false} md={4} />
      </Grid>

      {/* Custom Add Modal */}
      <AddItemModal
        open={open}
        setOpen={setOpen}
        newItem={newItem}
        newItemError={newItemError}
        users={users}
        userGroups={userGroups}
        handleAddItemChange={handleAddItemChange}
        onAdd={onAdd}
      />
      {/* Create New List Modal */}
      <CreateNewListModal
        open={createNewListOpen}
        setOpen={setCreateNewListOpen}
        createNewList={createNewList}
      />
      {/* Share List Modal */}
      <ShareListModal
        open={shareListOpen}
        setOpen={setShareListOpen}
        addUserToList={addUserToList}
        currentUserID={userID}
      />

      {/* Display Button When An Item is Checked */}
      {checkedItems.size > 0 && (
        <>
          {/* Button Background Glow */}
          <Grid
            container
            justify="center"
            className={classes.checkoutButtonContainer}
          >
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                className={classes.checkoutButtonBg}
              >
                Check Out
              </Button>
            </Grid>
          </Grid>

          {/* Checkout Button */}
          <Grid
            container
            justify="center"
            className={classes.checkoutButtonContainer}
          >
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                className={classes.checkoutButton}
                onClick={onCheckOut}
              >
                Check Out
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
}

export default GroceryList;
