import React from "react";
// Material UI Imports
import { Grid, List, Typography } from "@material-ui/core";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import { makeStyles } from "@material-ui/core/styles";
// Custom Imports
import CheckoutItem from "./CheckoutItem";

const useStyles = makeStyles(() => ({
  list: {
    width: "100%",
    paddingBottom: "0",
  },
  title: {
    fontSize: "200%",
    fontFamily: "Arial, Helvetica, sans-serif",
  },
  total: {
    background: "lightgray",
    padding: "2%",
  },
}));

function Checkout(props) {
  // Styles
  const classes = useStyles();

  // State and Props
  const [items, setItems] = React.useState([]);
  const [membersOwe, setMembersOwe] = React.useState({});
  const users = props.location.state.users;

  // Change Handlers
  const handlePriceChange = (index, event, value) => {
    let newItems = [...items];
    newItems[index]["price"] = value;
    setItems(newItems);
  };

  // Effects
  React.useEffect(() => {
    setItems(props.location.state.items.map((item) => {
      item["price"] = 0;
      return item;
    }))
  }, [props.location.state.items]);

  React.useEffect(() => {
    let newMembersOwe = {};
    users.forEach((user) => {
      newMembersOwe[user.id] = { name: user.name, total: 0 };
    });

    items.forEach((item) => {
      item.split.forEach((member) => {
        newMembersOwe[member]["total"] += item.price / item.split.length
      })
    })

    setMembersOwe(newMembersOwe);
  }, [users, items]);

  return (
    <Grid container>
      <Grid item sm={false} md={4} />
      {/* Main Content */}
      <Grid container item xs={12} md={4}>
        {/* Title */}
        <Grid item>
          <Typography className={classes.title}>Checkout</Typography>
        </Grid>
        {/* List of Items */}
        <Grid item xs={12}>
          <List component="nav" className={classes.list}>
            {items.map((item, index) => (
              <CheckoutItem
                key={item.id}
                item={item}
                index={index}
                onChange={handlePriceChange}
              />
            ))}
          </List>
        </Grid>
        {/* Totals */}
        <Grid item container className={classes.total}>
          {Object.values(membersOwe).map((member) => {
            return (
              <Grid item container justify="space-between" xs={12}>
                <Grid item>
                  <Typography>{member.name}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography>
                    <CurrencyTextField
                      value={member.total}
                      readOnly={true}
                      InputProps={{ disableUnderline: true }}
                    />
                  </Typography>
                </Grid>
              </Grid>
            );
          })}
          <Grid item container justify="space-between" xs={12}>
            <Grid item>
              <Typography>Total</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>
                <CurrencyTextField
                  value={items.reduce((a, b) => ({ price: a.price + b.price }), { price: 0 }).price}
                  readOnly={true}
                  InputProps={{ disableUnderline: true }}
                />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={false} md={4} />
    </Grid>
  );
}

export default Checkout;
