import React from "react";
// Material UI Imports
import { List as MaterialUIList } from "@material-ui/core";
// import { makeStyles } from "@material-ui/core/styles";

// Custom Imports
import ListItem from "./ListItem";

// Theme and Styles
// const useStyles = makeStyles((theme) => ({}));

// Equality Check Between Renders
function areEqual(prevProps, nextProps) {
  return (
    prevProps.currentListID === nextProps.currentListID &&
    prevProps.groceryItems === nextProps.groceryItems &&
    prevProps.checkedItems === nextProps.checkedItems &&
    prevProps.expandedPanels === nextProps.expandedPanels &&
    prevProps.users === nextProps.users &&
    prevProps.userGroups === nextProps.userGroups
  );
}

const List = React.memo((props) => {
  // Styles
  //   const classes = useStyles();

  // State and Props
  const {
    groceryItems,
    checkedItems,
    handleChecked,
    expandedPanels,
    handleExpanded,
    users,
    userGroups,
  } = props;

  return (
    <MaterialUIList>
      {groceryItems.map((value, index) => {
        return (
          <ListItem
            key={value.id}
            item={value}
            index={index}
            checked={
              checkedItems.get(value.id) !== undefined &&
              checkedItems.get(value.id)
            }
            handleChecked={handleChecked}
            expanded={
              expandedPanels.get(value.id) !== undefined &&
              expandedPanels.get(value.id)
            }
            handleExpanded={handleExpanded}
            users={users}
            userGroups={userGroups}
            {...props}
          />
        );
      })}
    </MaterialUIList>
  );
}, areEqual);

export default List;
