import React from "react";
import PropTypes from "prop-types";
import {
  Backdrop,
  Button,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { useSpring, animated } from "react-spring/web.cjs"; // web.cjs is required for IE 11 support


const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

export default function ShareListModal(props) {
  const classes = useStyles();

  // State and Props
  const [userID, setUserID] = React.useState("");
  const { open, setOpen, addUserToList, currentUserID } = props;

  // Change Handlers
  const handleUserIDChange = (event) => {
    setUserID(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    setUserID("");
  }, [open]);

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            spacing={1}
          >
            <Grid container item xs={12}>
              <Grid item xs={1}>
                <IconButton onClick={() => handleClose()}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="User ID"
                fullWidth={true}
                margin="dense"
                value={userID}
                onChange={handleUserIDChange}
              />
              <Typography>Your User ID: {currentUserID}</Typography>
            </Grid>
            <Grid container item xs={12} justify="center">
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => addUserToList(userID)}
                >
                  Share List
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Fade>
    </Modal>
  );
}
