import React from "react";
// Material UI Imports
import {
  AppBar,
  Button,
  Chip,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PersonIcon from "@material-ui/icons/Person";
import GroupIcon from "@material-ui/icons/Group";
import DeleteIcon from "@material-ui/icons/Delete";
// Amplify Imports
import { API, graphqlOperation } from "aws-amplify";
import { updateListItem, updateList } from "../graphql/mutations";

// Custom Imports
import TabPanel from "./TabPanel";
import ConfirmationDialog from "./ConfirmationDialog";
import CreateNewGroupModal from "./CreateNewGroupModal";

// Theme and Styles
const useStyles = makeStyles((theme) => ({
  tabs: {
    flexGrow: 1,
    width: "100%",
    margin: "0",
  },
  deleteButton: {
    background: "red",
    color: "white",
    "&:hover": {
      background: "darkred",
    },
  },
}));

const ListItemContent = React.memo((props) => {
  // Styles
  const classes = useStyles();

  // State
  const [expandedSplit, setExpandedSplit] = React.useState(false);
  const [tabValue, setTabValue] = React.useState(0);
  const [chipStates, setChipStates] = React.useState(new Map());
  const [groupChip, setGroupChip] = React.useState("");
  const [chipsError, setChipsError] = React.useState(false);
  const [category, setCategory] = React.useState("");
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);
  const [createNewGroupOpen, setCreateNewGroupOpen] = React.useState(false);
  const { currentListID, edited, setEdited } = props;

  // Change Handlers
  const handleSplitExpansion = (object, expanded) => {
    setExpandedSplit(expanded);
  };

  const handleTabChange = (event, newValue) => {

    setTabValue(newValue);
  };

  function onChipClick(userID) {
    setChipsError(false);
    setChipStates((prevChipStates) => {
      prevChipStates.set(userID, !prevChipStates.get(userID));
      return new Map(prevChipStates);
    });
    setGroupChip("");
  }

  function onGroupChipClick(group) {
    setChipsError(false);

    // Handles Group Chip Toggling
    let groupName = Object.keys(group)[0];
    if (groupName === groupChip) {
      setGroupChip("");
    } else {
      setGroupChip(groupName);
    }

    // Sets user chips in group
    let users = Object.values(Object.values(group)[0])[0];
    let newChipStates = new Map();
    users.forEach((user) => {
      newChipStates.set(user.id, true);
    });
    setChipStates(newChipStates);
  }

  function onCreateNewGroup() {
    setCreateNewGroupOpen(true);
  }

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  async function onConfirmationClick() {
    let noneSelected = true;
    let updatedItem = { ...props.item };
    updatedItem["item"] = props.itemName;
    updatedItem["category"] = category;
    updatedItem["split"] = [];
    chipStates.forEach((value, key) => {
      if (value) {
        updatedItem["split"].push(key);
        noneSelected = false;
      }
    });

    if (noneSelected) {
      setChipsError(true);
      setExpandedSplit(true);
    } else {
      await API.graphql(
        graphqlOperation(updateListItem, { input: updatedItem })
      );
    }
  }

  async function handleGroupDelete(index) {
    let groups = [...props.userGroups];
    groups.splice(index, 1);
    const updatedList = {
      id: currentListID,
      groups: groups
    };
    await API.graphql(
      graphqlOperation(updateList, { input: updatedList })
    );
  };

  // Functions
  async function createNewGroup(name, users) {
    let groups = [...props.userGroups];
    groups.push(JSON.stringify({
      [name]: {
        users
      },
    }));
    const updatedList = {
      id: currentListID,
      groups: groups
    };
    await API.graphql(
      graphqlOperation(updateList, { input: updatedList })
    );
    setCreateNewGroupOpen(false);
  }

  // Sets active chips and category
  React.useEffect(() => {
    let newChipStates = new Map();
    props.users.forEach((user) => {
      newChipStates.set(user.id, props.item.split.includes(user.id));
    });
    setCategory(props.item.category);
    setChipStates(newChipStates);
  }, [props.users, props.item]);

  // Checks for chip or category change
  React.useEffect(() => {
    function isEdited() {
      // Checks if name is the same
      if (props.itemName !== props.item.item) {
        return true;
      }

      // Checks if category is the same
      if (category !== props.item.category) {
        return true;
      }

      // Checks is chips are the same
      var a1 = props.item.split.concat().sort();
      var a2 = [];
      chipStates.forEach((value, key) => {
        if (value) {
          a2.push(key);
        }
      });
      a2.sort();

      let superSet = {};
      for (let i = 0; i < a1.length; i++) {
        const e = a1[i] + typeof a1[i];
        superSet[e] = 1;
      }

      for (let i = 0; i < a2.length; i++) {
        const e = a2[i] + typeof a2[i];
        if (!superSet[e]) {
          return true;
        }
        superSet[e] = 2;
      }

      for (let e in superSet) {
        if (superSet[e] === 1) {
          return true;
        }
      }

      return false;
    }

    setEdited(isEdited());
  });

  return (
    <Grid container justify="space-between" alignItems="center" spacing={1}>
      <Grid item xs={12}>
        <ExpansionPanel
          style={{ width: "100%" }}
          onChange={handleSplitExpansion}
          expanded={expandedSplit}
        >
          <ExpansionPanelSummary
            classes={{
              expandIcon: classes.expandIcon,
              expanded: classes.expanded,
            }}
            expandIcon={<ExpandMoreIcon />}
            aria-label="Expand"
            aria-controls="additional-actions1-content"
            id="additional-actions1-header"
          >
            <Typography>Split</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className={classes.tabs}>
              <AppBar position="static">
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  variant="fullWidth"
                  centered
                >
                  <Tab label="People" />
                  <Tab label="Groups" />
                </Tabs>
              </AppBar>
              <TabPanel value={tabValue} index={0}>
                <Grid
                  container
                  direction="column"
                  justify="space-between"
                  alignItems="center"
                  spacing={1}
                >
                  {props.users.map((user) => {
                    return (
                      <Grid key={user.id} item>
                        <Chip
                          icon={<PersonIcon />}
                          label={user.name}
                          clickable
                          color={chipsError ? "secondary" : "primary"}
                          onClick={() => onChipClick(user.id)}
                          variant={
                            chipStates.get(user.id) ? "default" : "outlined"
                          }
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <Grid
                  container
                  direction="column"
                  justify="space-between"
                  alignItems="center"
                  spacing={1}
                >
                  {props.userGroups.map((jsonGroup, index) => {
                    let group = JSON.parse(jsonGroup);
                    const groupName = Object.keys(group)[0];
                    return (
                      <Grid key={groupName} item>
                        <Chip
                          icon={<GroupIcon />}
                          label={groupName}
                          clickable
                          color={chipsError ? "secondary" : "primary"}
                          onClick={() => onGroupChipClick(group)}
                          variant={
                            groupChip === groupName ? "default" : "outlined"
                          }
                          onDelete={() => handleGroupDelete(index)}
                        />
                      </Grid>
                    );
                  })}
                  <Grid item>
                    <Chip
                      label="+ Create New Group"
                      clickable
                      onClick={() => onCreateNewGroup()}
                      variant="default"
                    />
                  </Grid>
                </Grid>
              </TabPanel>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Grid>
      <Grid item xs={12}>
        <ExpansionPanel style={{ width: "100%" }}>
          <ExpansionPanelSummary
            classes={{
              expandIcon: classes.expandIcon,
              expanded: classes.expanded,
            }}
            expandIcon={<ExpandMoreIcon />}
            aria-label="Expand"
            aria-controls="additional-actions1-content"
            id="additional-actions1-header"
          >
            <Typography>Category</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <TextField
              label="Category"
              fullWidth={true}
              margin="dense"
              value={category}
              onChange={handleCategoryChange}
            />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Grid>
      <Grid container item xs={12} justify="space-evenly">
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setConfirmationOpen(true)}
            startIcon={<DeleteIcon />}
            className={classes.deleteButton}
          >
            Delete
          </Button>
        </Grid>
        {edited && (
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={() => onConfirmationClick()}
            >
              Confirm Changes
            </Button>
          </Grid>
        )}
      </Grid>
      <ConfirmationDialog
        title="Delete Item?"
        open={confirmationOpen}
        setOpen={setConfirmationOpen}
        onConfirm={() => {
          props.onDelete(props.item);
        }}
      >
        Are you sure you want to delete this item?
      </ConfirmationDialog>
      {/* Create New Group Modal */}
      <CreateNewGroupModal
        open={createNewGroupOpen}
        setOpen={setCreateNewGroupOpen}
        createNewGroup={createNewGroup}
        users={props.users}
        userGroups={props.userGroups}
      />
    </Grid>
  );
});

export default ListItemContent;
