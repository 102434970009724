/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        subID
        name
        createdAt
        updatedAt
        ownedLists {
          items {
            id
            title
            groups
            createdAt
            updatedAt
            owner {
              id
              subID
              name
              createdAt
              updatedAt
            }
            items {
              nextToken
            }
            users {
              nextToken
            }
          }
          nextToken
        }
        lists {
          items {
            id
            userID
            listID
            createdAt
            updatedAt
            user {
              id
              subID
              name
              createdAt
              updatedAt
            }
            list {
              id
              title
              groups
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      subID
      name
      createdAt
      updatedAt
      ownedLists {
        items {
          id
          title
          groups
          createdAt
          updatedAt
          owner {
            id
            subID
            name
            createdAt
            updatedAt
            ownedLists {
              nextToken
            }
            lists {
              nextToken
            }
          }
          items {
            items {
              id
              item
              category
              split
              listItemListId
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            items {
              id
              userID
              listID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      lists {
        items {
          id
          userID
          listID
          createdAt
          updatedAt
          user {
            id
            subID
            name
            createdAt
            updatedAt
            ownedLists {
              nextToken
            }
            lists {
              nextToken
            }
          }
          list {
            id
            title
            groups
            createdAt
            updatedAt
            owner {
              id
              subID
              name
              createdAt
              updatedAt
            }
            items {
              nextToken
            }
            users {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const userBySubId = /* GraphQL */ `
  query UserBySubId(
    $subID: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userBySubID(
      subID: $subID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        subID
        name
        createdAt
        updatedAt
        ownedLists {
          items {
            id
            title
            groups
            createdAt
            updatedAt
            owner {
              id
              subID
              name
              createdAt
              updatedAt
            }
            items {
              nextToken
            }
            users {
              nextToken
            }
          }
          nextToken
        }
        lists {
          items {
            id
            userID
            listID
            createdAt
            updatedAt
            user {
              id
              subID
              name
              createdAt
              updatedAt
            }
            list {
              id
              title
              groups
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listLists = /* GraphQL */ `
  query ListLists(
    $filter: ModelListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        groups
        createdAt
        updatedAt
        owner {
          id
          subID
          name
          createdAt
          updatedAt
          ownedLists {
            items {
              id
              title
              groups
              createdAt
              updatedAt
            }
            nextToken
          }
          lists {
            items {
              id
              userID
              listID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        items {
          items {
            id
            item
            category
            split
            listItemListId
            createdAt
            updatedAt
            list {
              id
              title
              groups
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        users {
          items {
            id
            userID
            listID
            createdAt
            updatedAt
            user {
              id
              subID
              name
              createdAt
              updatedAt
            }
            list {
              id
              title
              groups
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getList = /* GraphQL */ `
  query GetList($id: ID!) {
    getList(id: $id) {
      id
      title
      groups
      createdAt
      updatedAt
      owner {
        id
        subID
        name
        createdAt
        updatedAt
        ownedLists {
          items {
            id
            title
            groups
            createdAt
            updatedAt
            owner {
              id
              subID
              name
              createdAt
              updatedAt
            }
            items {
              nextToken
            }
            users {
              nextToken
            }
          }
          nextToken
        }
        lists {
          items {
            id
            userID
            listID
            createdAt
            updatedAt
            user {
              id
              subID
              name
              createdAt
              updatedAt
            }
            list {
              id
              title
              groups
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      items {
        items {
          id
          item
          category
          split
          listItemListId
          createdAt
          updatedAt
          list {
            id
            title
            groups
            createdAt
            updatedAt
            owner {
              id
              subID
              name
              createdAt
              updatedAt
            }
            items {
              nextToken
            }
            users {
              nextToken
            }
          }
        }
        nextToken
      }
      users {
        items {
          id
          userID
          listID
          createdAt
          updatedAt
          user {
            id
            subID
            name
            createdAt
            updatedAt
            ownedLists {
              nextToken
            }
            lists {
              nextToken
            }
          }
          list {
            id
            title
            groups
            createdAt
            updatedAt
            owner {
              id
              subID
              name
              createdAt
              updatedAt
            }
            items {
              nextToken
            }
            users {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const getListItem = /* GraphQL */ `
  query GetListItem($id: ID!) {
    getListItem(id: $id) {
      id
      item
      category
      split
      listItemListId
      createdAt
      updatedAt
      list {
        id
        title
        groups
        createdAt
        updatedAt
        owner {
          id
          subID
          name
          createdAt
          updatedAt
          ownedLists {
            items {
              id
              title
              groups
              createdAt
              updatedAt
            }
            nextToken
          }
          lists {
            items {
              id
              userID
              listID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        items {
          items {
            id
            item
            category
            split
            listItemListId
            createdAt
            updatedAt
            list {
              id
              title
              groups
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        users {
          items {
            id
            userID
            listID
            createdAt
            updatedAt
            user {
              id
              subID
              name
              createdAt
              updatedAt
            }
            list {
              id
              title
              groups
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const listListItems = /* GraphQL */ `
  query ListListItems(
    $filter: ModelListItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listListItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        item
        category
        split
        listItemListId
        createdAt
        updatedAt
        list {
          id
          title
          groups
          createdAt
          updatedAt
          owner {
            id
            subID
            name
            createdAt
            updatedAt
            ownedLists {
              nextToken
            }
            lists {
              nextToken
            }
          }
          items {
            items {
              id
              item
              category
              split
              listItemListId
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            items {
              id
              userID
              listID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
