import React from "react";
// import { makeStyles } from "@material-ui/core/styles";

// Amplify imports
// import { AmplifySignOut } from "@aws-amplify/ui-react";
import { API, graphqlOperation, Auth } from "aws-amplify";
import { userBySubId } from "../graphql/queries";

import CreateUser from "./CreateUser";
import GroceryList from "./GroceryList";

// Style Classes
// const useStyles = makeStyles((theme) => ({
// }));

function Home() {
  // Styles
  // const classes = useStyles();

  const [userExists, setUserExists] = React.useState();
  const [userSubID, setUserSubID] = React.useState("");

  React.useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(async (userData) => {
        setUserSubID(userData.attributes.sub);
        const user = await API.graphql(
          graphqlOperation(userBySubId, { subID: userData.attributes.sub })
        );

        // Check if user exists
        if (user.data.userBySubID.items[0]) {
          setUserExists(true);
        } else {
          setUserExists(false);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div>
      {userExists == null ? (
        <div>Loading...</div>
      ) : userExists ? (
        <GroceryList userSubID={userSubID} />
      ) : (
            <CreateUser userSubID={userSubID} setUserExists={setUserExists} />
          )}
    </div>
  );
}

export default Home;
