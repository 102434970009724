/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:0de54886-b940-44b9-a7e9-7a8e9154d485",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_mwhRJxsRV",
    "aws_user_pools_web_client_id": "ist8k57hl8jea0p9qtqf2hd0p",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://mknxoztcmfcwta3vvs24hjjccu.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
