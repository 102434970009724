import React from "react";
import PropTypes from "prop-types";
import {
  Backdrop,
  Button,
  Chip,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import PersonIcon from "@material-ui/icons/Person";
import { useSpring, animated } from "react-spring/web.cjs"; // web.cjs is required for IE 11 support


const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  userList: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
  }
}));

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

export default function CreateNewListModal(props) {
  const classes = useStyles();

  // State and Props
  const [name, setName] = React.useState("");
  const [nameError, setNameError] = React.useState(false);
  const [repeatNameError, setRepeatNameError] = React.useState(false);
  const [chipsState, setChipsState] = React.useState(new Map());
  const [chipsError, setChipsError] = React.useState(false);
  const { open, setOpen, createNewGroup } = props;

  // Change Handlers
  const handleNameChange = (event) => {
    if (nameError) {
      setNameError(false);
    }

    if (repeatNameError) {
      setRepeatNameError(false);
    }

    setName(event.target.value);
  };

  function onChipClick(userID) {
    setChipsError(false);
    setChipsState((prevChipsState) => {
      prevChipsState.set(userID, !prevChipsState.get(userID));
      return new Map(prevChipsState);
    });
  }

  const handleClose = () => {
    setOpen(false);
  };

  const onCreateGroup = () => {
    let emptyName = false;
    let repeatName = false;
    if (!/\S/.test(name)) {
      setNameError(true);
      emptyName = true;
    } else {
      let groups = [...props.userGroups];
      groups.forEach((jsonGroup) => {
        let group = JSON.parse(jsonGroup);
        const groupName = Object.keys(group)[0];
        if (groupName === name) {
          setRepeatNameError(true);
          repeatName = true;
        }
      });
    }

    let noneSelected = true;
    let users = [];
    chipsState.forEach((value, key) => {
      if (value) {
        users.push(key);
        noneSelected = false;
      }
    });

    if (noneSelected) {
      setChipsError(true);
    }

    if (!noneSelected && !emptyName && !repeatName) {
      setName("");
      setChipsState(new Map())
      createNewGroup(name, users);
    }
  };
  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid container item xs={12}>
              <Grid item xs={1}>
                <IconButton onClick={() => handleClose()}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                Group Name
              </Typography>
              <TextField
                error={nameError || repeatNameError}
                fullWidth={true}
                margin="dense"
                value={name}
                onChange={handleNameChange}
                helperText={repeatNameError && "There is already a group with that name"}
              />
            </Grid>
            <Grid item>
              <Typography>
                Group Members
              </Typography>
            </Grid>
            <Grid
              item
              container
              direction="column"
              justify="space-between"
              alignItems="center"
              spacing={1}
              className={classes.userList}
            >
              {props.users.map((user) => {
                return (
                  <Grid key={user.id} item>
                    <Chip
                      icon={<PersonIcon />}
                      label={user.name}
                      clickable
                      color={chipsError ? "secondary" : "primary"}
                      onClick={() => onChipClick(user)}
                      variant={
                        chipsState.get(user) ? "default" : "outlined"
                      }
                    />
                  </Grid>
                );
              })}
            </Grid>
            <Grid container item xs={12} justify="center">
              <Grid item>
                <Button
                  variant="contained"
                  onClick={onCreateGroup}
                >
                  Create Group
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Fade>
    </Modal>
  );
}
