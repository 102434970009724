import React from "react";
import {
  Checkbox,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  FormControlLabel,
  ListItem as MaterialUIListItem,
  IconButton,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import ListItemContent from "./ListItemContent";
import ConfirmationDialog from "./ConfirmationDialog";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  listItem: {
    width: "100%",
  },
  expandIcon: {
    "&$expanded": {
      transform: "none",
    },
  },
  expanded: {},
  tabs: {
    flexGrow: 1,
    width: "100%",
    margin: "0",
  },
  checkoutButtonContainer: {
    position: "fixed",
    bottom: "10%",
  },
  checkoutButton: {
    boxShadow: "16px rgba(0,0,0,0), 20px rgba(0, 0, 0,0.2)",
  },
  checkoutButtonBg: {
    animation: `$myEffect 3000ms infinite ${theme.transitions.easing.easeInOut}`,
  },
  "@keyframes myEffect": {
    "0%": {
      transform: "scale(1, 1)",
    },
    "50%": {
      opacity: 0.3,
    },
    "100%": {
      transform: "scale(1.1)",
      opacity: 0,
    },
  },
}));

// Equality Check Between Renders
function areEqual(prevProps, nextProps) {
  return (
    prevProps.currentListID === nextProps.currentListID &&
    prevProps.checked === nextProps.checked &&
    prevProps.expanded === nextProps.expanded &&
    prevProps.item === nextProps.item &&
    prevProps.index === nextProps.index &&
    prevProps.users === nextProps.users &&
    prevProps.userGroups === nextProps.userGroups
  );
}

const ListItem = React.memo((props) => {
  const classes = useStyles();

  // State
  const [reset, setReset] = React.useState(0);
  const [itemName, setItemName] = React.useState(props.item.item);
  const [edited, setEdited] = React.useState(false);
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);

  // Open/Close Handler
  function handleExpansion(open) {
    if (open) {
      props.handleExpanded(props.item.id);
    } else {
      if (!edited) {
        props.handleExpanded(props.item.id);
      } else {
        setConfirmationOpen(true);
      }
    }
  }

  const handleItemNameChange = (event) => {
    setItemName(event.target.value);
  };

  return (
    <MaterialUIListItem key={props.index}>
      <ExpansionPanel
        style={{ width: "100%" }}
        key={props.item.id}
        expanded={props.expanded}
      >
        <ExpansionPanelSummary
          classes={{
            expandIcon: classes.expandIcon,
            expanded: classes.expanded,
          }}
          expandIcon={
            props.expanded ? (
              <IconButton onClick={() => handleExpansion(false)}>
                <CloseIcon />
              </IconButton>
            ) : (
              <IconButton onClick={() => handleExpansion(true)}>
                <EditIcon />
              </IconButton>
            )
          }
          aria-label="Expand"
          aria-controls="additional-actions1-content"
          id="additional-actions1-header"
        >
          <FormControlLabel
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
            control={
              <Checkbox
                checked={props.checked}
                onChange={props.handleChecked}
                value={props.item.id}
                color="primary"
              />
            }
            label={
              props.expanded ? (
                <TextField onChange={handleItemNameChange} value={itemName} />
              ) : (
                props.item.item
              )
            }
          />
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <ListItemContent
            key={reset}
            edited={edited}
            setEdited={setEdited}
            itemName={itemName}
            {...props}
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ConfirmationDialog
        title="Cancel Changes?"
        open={confirmationOpen}
        setOpen={setConfirmationOpen}
        onConfirm={() => {
          setReset((prevReset) => prevReset + 1);
          setItemName(props.item.item);
          props.handleExpanded(props.item.id);
        }}
      >
        Are you sure you want to cancel your changes?
      </ConfirmationDialog>
    </MaterialUIListItem>
  );
}, areEqual);

export default ListItem;
