import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { AmplifySignOut, withAuthenticator } from "@aws-amplify/ui-react";

// Components
import Home from "./components/Home";
import Checkout from "./components/Checkout/Checkout";

// Style Classes
const useStyles = makeStyles((theme) => ({
  fixedBottom: {
    position: "fixed",
    bottom: 0,
    width: "100%"
  },
}));


function App() {
  // Styles
  const classes = useStyles();

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/Checkout" component={Checkout} />
        <Route path="/" component={Home} />
      </Switch>
      <AmplifySignOut className={classes.fixedBottom} />
    </BrowserRouter>
  );
}

export default withAuthenticator(App);
