/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onUpdateList = /* GraphQL */ `
  subscription OnUpdateList($id: ID) {
    onUpdateList(id: $id) {
      id
      title
      groups
      createdAt
      updatedAt
      owner {
        id
        subID
        name
        createdAt
        updatedAt
        ownedLists {
          items {
            id
            title
            groups
            createdAt
            updatedAt
            owner {
              id
              subID
              name
              createdAt
              updatedAt
            }
            items {
              nextToken
            }
            users {
              nextToken
            }
          }
          nextToken
        }
        lists {
          items {
            id
            userID
            listID
            createdAt
            updatedAt
            user {
              id
              subID
              name
              createdAt
              updatedAt
            }
            list {
              id
              title
              groups
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      items {
        items {
          id
          item
          category
          split
          listItemListId
          createdAt
          updatedAt
          list {
            id
            title
            groups
            createdAt
            updatedAt
            owner {
              id
              subID
              name
              createdAt
              updatedAt
            }
            items {
              nextToken
            }
            users {
              nextToken
            }
          }
        }
        nextToken
      }
      users {
        items {
          id
          userID
          listID
          createdAt
          updatedAt
          user {
            id
            subID
            name
            createdAt
            updatedAt
            ownedLists {
              nextToken
            }
            lists {
              nextToken
            }
          }
          list {
            id
            title
            groups
            createdAt
            updatedAt
            owner {
              id
              subID
              name
              createdAt
              updatedAt
            }
            items {
              nextToken
            }
            users {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const onCreateListItem = /* GraphQL */ `
  subscription OnCreateListItem($listItemListId: ID) {
    onCreateListItem(listItemListId: $listItemListId) {
      id
      item
      category
      split
      listItemListId
      createdAt
      updatedAt
      list {
        id
        title
        groups
        createdAt
        updatedAt
        owner {
          id
          subID
          name
          createdAt
          updatedAt
          ownedLists {
            items {
              id
              title
              groups
              createdAt
              updatedAt
            }
            nextToken
          }
          lists {
            items {
              id
              userID
              listID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        items {
          items {
            id
            item
            category
            split
            listItemListId
            createdAt
            updatedAt
            list {
              id
              title
              groups
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        users {
          items {
            id
            userID
            listID
            createdAt
            updatedAt
            user {
              id
              subID
              name
              createdAt
              updatedAt
            }
            list {
              id
              title
              groups
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const onUpdateListItem = /* GraphQL */ `
  subscription OnUpdateListItem($listItemListId: ID) {
    onUpdateListItem(listItemListId: $listItemListId) {
      id
      item
      category
      split
      listItemListId
      createdAt
      updatedAt
      list {
        id
        title
        groups
        createdAt
        updatedAt
        owner {
          id
          subID
          name
          createdAt
          updatedAt
          ownedLists {
            items {
              id
              title
              groups
              createdAt
              updatedAt
            }
            nextToken
          }
          lists {
            items {
              id
              userID
              listID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        items {
          items {
            id
            item
            category
            split
            listItemListId
            createdAt
            updatedAt
            list {
              id
              title
              groups
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        users {
          items {
            id
            userID
            listID
            createdAt
            updatedAt
            user {
              id
              subID
              name
              createdAt
              updatedAt
            }
            list {
              id
              title
              groups
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const onDeleteListItem = /* GraphQL */ `
  subscription OnDeleteListItem($listItemListId: ID) {
    onDeleteListItem(listItemListId: $listItemListId) {
      id
      item
      category
      split
      listItemListId
      createdAt
      updatedAt
      list {
        id
        title
        groups
        createdAt
        updatedAt
        owner {
          id
          subID
          name
          createdAt
          updatedAt
          ownedLists {
            items {
              id
              title
              groups
              createdAt
              updatedAt
            }
            nextToken
          }
          lists {
            items {
              id
              userID
              listID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        items {
          items {
            id
            item
            category
            split
            listItemListId
            createdAt
            updatedAt
            list {
              id
              title
              groups
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        users {
          items {
            id
            userID
            listID
            createdAt
            updatedAt
            user {
              id
              subID
              name
              createdAt
              updatedAt
            }
            list {
              id
              title
              groups
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const onCreateUserList = /* GraphQL */ `
  subscription OnCreateUserList($listID: ID) {
    onCreateUserList(listID: $listID) {
      id
      userID
      listID
      createdAt
      updatedAt
      user {
        id
        subID
        name
        createdAt
        updatedAt
        ownedLists {
          items {
            id
            title
            groups
            createdAt
            updatedAt
            owner {
              id
              subID
              name
              createdAt
              updatedAt
            }
            items {
              nextToken
            }
            users {
              nextToken
            }
          }
          nextToken
        }
        lists {
          items {
            id
            userID
            listID
            createdAt
            updatedAt
            user {
              id
              subID
              name
              createdAt
              updatedAt
            }
            list {
              id
              title
              groups
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      list {
        id
        title
        groups
        createdAt
        updatedAt
        owner {
          id
          subID
          name
          createdAt
          updatedAt
          ownedLists {
            items {
              id
              title
              groups
              createdAt
              updatedAt
            }
            nextToken
          }
          lists {
            items {
              id
              userID
              listID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        items {
          items {
            id
            item
            category
            split
            listItemListId
            createdAt
            updatedAt
            list {
              id
              title
              groups
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        users {
          items {
            id
            userID
            listID
            createdAt
            updatedAt
            user {
              id
              subID
              name
              createdAt
              updatedAt
            }
            list {
              id
              title
              groups
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const onDeleteUserList = /* GraphQL */ `
  subscription OnDeleteUserList($listID: ID) {
    onDeleteUserList(listID: $listID) {
      id
      userID
      listID
      createdAt
      updatedAt
      user {
        id
        subID
        name
        createdAt
        updatedAt
        ownedLists {
          items {
            id
            title
            groups
            createdAt
            updatedAt
            owner {
              id
              subID
              name
              createdAt
              updatedAt
            }
            items {
              nextToken
            }
            users {
              nextToken
            }
          }
          nextToken
        }
        lists {
          items {
            id
            userID
            listID
            createdAt
            updatedAt
            user {
              id
              subID
              name
              createdAt
              updatedAt
            }
            list {
              id
              title
              groups
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      list {
        id
        title
        groups
        createdAt
        updatedAt
        owner {
          id
          subID
          name
          createdAt
          updatedAt
          ownedLists {
            items {
              id
              title
              groups
              createdAt
              updatedAt
            }
            nextToken
          }
          lists {
            items {
              id
              userID
              listID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        items {
          items {
            id
            item
            category
            split
            listItemListId
            createdAt
            updatedAt
            list {
              id
              title
              groups
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        users {
          items {
            id
            userID
            listID
            createdAt
            updatedAt
            user {
              id
              subID
              name
              createdAt
              updatedAt
            }
            list {
              id
              title
              groups
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      id
      subID
      name
      createdAt
      updatedAt
      ownedLists {
        items {
          id
          title
          groups
          createdAt
          updatedAt
          owner {
            id
            subID
            name
            createdAt
            updatedAt
            ownedLists {
              nextToken
            }
            lists {
              nextToken
            }
          }
          items {
            items {
              id
              item
              category
              split
              listItemListId
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            items {
              id
              userID
              listID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      lists {
        items {
          id
          userID
          listID
          createdAt
          updatedAt
          user {
            id
            subID
            name
            createdAt
            updatedAt
            ownedLists {
              nextToken
            }
            lists {
              nextToken
            }
          }
          list {
            id
            title
            groups
            createdAt
            updatedAt
            owner {
              id
              subID
              name
              createdAt
              updatedAt
            }
            items {
              nextToken
            }
            users {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      id
      subID
      name
      createdAt
      updatedAt
      ownedLists {
        items {
          id
          title
          groups
          createdAt
          updatedAt
          owner {
            id
            subID
            name
            createdAt
            updatedAt
            ownedLists {
              nextToken
            }
            lists {
              nextToken
            }
          }
          items {
            items {
              id
              item
              category
              split
              listItemListId
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            items {
              id
              userID
              listID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      lists {
        items {
          id
          userID
          listID
          createdAt
          updatedAt
          user {
            id
            subID
            name
            createdAt
            updatedAt
            ownedLists {
              nextToken
            }
            lists {
              nextToken
            }
          }
          list {
            id
            title
            groups
            createdAt
            updatedAt
            owner {
              id
              subID
              name
              createdAt
              updatedAt
            }
            items {
              nextToken
            }
            users {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      id
      subID
      name
      createdAt
      updatedAt
      ownedLists {
        items {
          id
          title
          groups
          createdAt
          updatedAt
          owner {
            id
            subID
            name
            createdAt
            updatedAt
            ownedLists {
              nextToken
            }
            lists {
              nextToken
            }
          }
          items {
            items {
              id
              item
              category
              split
              listItemListId
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            items {
              id
              userID
              listID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      lists {
        items {
          id
          userID
          listID
          createdAt
          updatedAt
          user {
            id
            subID
            name
            createdAt
            updatedAt
            ownedLists {
              nextToken
            }
            lists {
              nextToken
            }
          }
          list {
            id
            title
            groups
            createdAt
            updatedAt
            owner {
              id
              subID
              name
              createdAt
              updatedAt
            }
            items {
              nextToken
            }
            users {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
