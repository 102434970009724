import React from "react";
// Material UI Imports
import { Button, Grid, TextField } from "@material-ui/core";
// import { makeStyles } from "@material-ui/core/styles";

// Amplify Imports
import { API, graphqlOperation } from "aws-amplify";
import { createUser, createList, createUserList } from "../graphql/mutations";

// const useStyles = makeStyles((theme) => ({}));

function CreateUser(props) {
  // Styles
  //   const classes = useStyles();

  // State
  const [name, setName] = React.useState("");

  // Change Handlers
  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const onFinish = async () => {
    const newUser = {
      subID: props.userSubID,
      name: name,
    };
    console.log(newUser);
    const createUserResponse = await API.graphql(
      graphqlOperation(createUser, { input: newUser })
    );
    const user = createUserResponse.data.createUser;
    console.log(user);

    const newList = {
      title: user.name + "'s List",
      listOwnerId: user.id,
    };
    const createListResponse = await API.graphql(
      graphqlOperation(createList, { input: newList })
    );
    const list = createListResponse.data.createList;
    console.log(list);

    const newUserList = {
      userID: user.id,
      listID: list.id,
    };

    await API.graphql(graphqlOperation(createUserList, { input: newUserList }));
    props.setUserExists(true);
  };

  return (
    <Grid container>
      <Grid item sm={false} md={4} />
      <Grid container item xs={12} md={4} justify="center">
        <Grid item xs={12}>
          <TextField
            label="Name"
            fullWidth
            margin="normal"
            variant="outlined"
            value={name}
            onChange={handleNameChange}
          />
        </Grid>
        <Grid container justify="center">
          <Grid item>
            <Button variant="contained" color="primary" onClick={onFinish}>
              Finish
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={false} md={4} />
    </Grid>
  );
}

export default CreateUser;
