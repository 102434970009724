export const onCreateListItem = /* GraphQL */ `
  subscription OnCreateListItem($listItemListId: ID) {
    onCreateListItem(listItemListId: $listItemListId) {
      id
      item
      category
      split
      listItemListId
    }
  }
`;
export const onUpdateListItem = /* GraphQL */ `
  subscription OnUpdateListItem($listItemListId: ID) {
    onUpdateListItem(listItemListId: $listItemListId) {
      id
      item
      category
      split
      listItemListId
    }
  }
`;
export const onDeleteListItem = /* GraphQL */ `
  subscription OnDeleteListItem($listItemListId: ID) {
    onDeleteListItem(listItemListId: $listItemListId) {
      id
      item
      category
      split
      listItemListId
    }
  }
`;

export const onCreateUserList = /* GraphQL */ `
  subscription OnCreateUserList($listID: ID) {
    onCreateUserList(listID: $listID) {
      userID
      list {
        id
        users {
          items {
            user {
              id
              name
            }
          }
          nextToken
        }
      }
    }
  }
`;
