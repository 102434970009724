/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const shareList = /* GraphQL */ `
  mutation ShareList($listID: ID, $newUserID: String) {
    shareList(listID: $listID, newUserID: $newUserID)
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      subID
      name
      createdAt
      updatedAt
      ownedLists {
        items {
          id
          title
          groups
          createdAt
          updatedAt
          owner {
            id
            subID
            name
            createdAt
            updatedAt
            ownedLists {
              nextToken
            }
            lists {
              nextToken
            }
          }
          items {
            items {
              id
              item
              category
              split
              listItemListId
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            items {
              id
              userID
              listID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      lists {
        items {
          id
          userID
          listID
          createdAt
          updatedAt
          user {
            id
            subID
            name
            createdAt
            updatedAt
            ownedLists {
              nextToken
            }
            lists {
              nextToken
            }
          }
          list {
            id
            title
            groups
            createdAt
            updatedAt
            owner {
              id
              subID
              name
              createdAt
              updatedAt
            }
            items {
              nextToken
            }
            users {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      subID
      name
      createdAt
      updatedAt
      ownedLists {
        items {
          id
          title
          groups
          createdAt
          updatedAt
          owner {
            id
            subID
            name
            createdAt
            updatedAt
            ownedLists {
              nextToken
            }
            lists {
              nextToken
            }
          }
          items {
            items {
              id
              item
              category
              split
              listItemListId
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            items {
              id
              userID
              listID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      lists {
        items {
          id
          userID
          listID
          createdAt
          updatedAt
          user {
            id
            subID
            name
            createdAt
            updatedAt
            ownedLists {
              nextToken
            }
            lists {
              nextToken
            }
          }
          list {
            id
            title
            groups
            createdAt
            updatedAt
            owner {
              id
              subID
              name
              createdAt
              updatedAt
            }
            items {
              nextToken
            }
            users {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      subID
      name
      createdAt
      updatedAt
      ownedLists {
        items {
          id
          title
          groups
          createdAt
          updatedAt
          owner {
            id
            subID
            name
            createdAt
            updatedAt
            ownedLists {
              nextToken
            }
            lists {
              nextToken
            }
          }
          items {
            items {
              id
              item
              category
              split
              listItemListId
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            items {
              id
              userID
              listID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      lists {
        items {
          id
          userID
          listID
          createdAt
          updatedAt
          user {
            id
            subID
            name
            createdAt
            updatedAt
            ownedLists {
              nextToken
            }
            lists {
              nextToken
            }
          }
          list {
            id
            title
            groups
            createdAt
            updatedAt
            owner {
              id
              subID
              name
              createdAt
              updatedAt
            }
            items {
              nextToken
            }
            users {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const createList = /* GraphQL */ `
  mutation CreateList(
    $input: CreateListInput!
    $condition: ModelListConditionInput
  ) {
    createList(input: $input, condition: $condition) {
      id
      title
      groups
      createdAt
      updatedAt
      owner {
        id
        subID
        name
        createdAt
        updatedAt
        ownedLists {
          items {
            id
            title
            groups
            createdAt
            updatedAt
            owner {
              id
              subID
              name
              createdAt
              updatedAt
            }
            items {
              nextToken
            }
            users {
              nextToken
            }
          }
          nextToken
        }
        lists {
          items {
            id
            userID
            listID
            createdAt
            updatedAt
            user {
              id
              subID
              name
              createdAt
              updatedAt
            }
            list {
              id
              title
              groups
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      items {
        items {
          id
          item
          category
          split
          listItemListId
          createdAt
          updatedAt
          list {
            id
            title
            groups
            createdAt
            updatedAt
            owner {
              id
              subID
              name
              createdAt
              updatedAt
            }
            items {
              nextToken
            }
            users {
              nextToken
            }
          }
        }
        nextToken
      }
      users {
        items {
          id
          userID
          listID
          createdAt
          updatedAt
          user {
            id
            subID
            name
            createdAt
            updatedAt
            ownedLists {
              nextToken
            }
            lists {
              nextToken
            }
          }
          list {
            id
            title
            groups
            createdAt
            updatedAt
            owner {
              id
              subID
              name
              createdAt
              updatedAt
            }
            items {
              nextToken
            }
            users {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const updateList = /* GraphQL */ `
  mutation UpdateList(
    $input: UpdateListInput!
    $condition: ModelListConditionInput
  ) {
    updateList(input: $input, condition: $condition) {
      id
      title
      groups
      createdAt
      updatedAt
      owner {
        id
        subID
        name
        createdAt
        updatedAt
        ownedLists {
          items {
            id
            title
            groups
            createdAt
            updatedAt
            owner {
              id
              subID
              name
              createdAt
              updatedAt
            }
            items {
              nextToken
            }
            users {
              nextToken
            }
          }
          nextToken
        }
        lists {
          items {
            id
            userID
            listID
            createdAt
            updatedAt
            user {
              id
              subID
              name
              createdAt
              updatedAt
            }
            list {
              id
              title
              groups
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      items {
        items {
          id
          item
          category
          split
          listItemListId
          createdAt
          updatedAt
          list {
            id
            title
            groups
            createdAt
            updatedAt
            owner {
              id
              subID
              name
              createdAt
              updatedAt
            }
            items {
              nextToken
            }
            users {
              nextToken
            }
          }
        }
        nextToken
      }
      users {
        items {
          id
          userID
          listID
          createdAt
          updatedAt
          user {
            id
            subID
            name
            createdAt
            updatedAt
            ownedLists {
              nextToken
            }
            lists {
              nextToken
            }
          }
          list {
            id
            title
            groups
            createdAt
            updatedAt
            owner {
              id
              subID
              name
              createdAt
              updatedAt
            }
            items {
              nextToken
            }
            users {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteList = /* GraphQL */ `
  mutation DeleteList(
    $input: DeleteListInput!
    $condition: ModelListConditionInput
  ) {
    deleteList(input: $input, condition: $condition) {
      id
      title
      groups
      createdAt
      updatedAt
      owner {
        id
        subID
        name
        createdAt
        updatedAt
        ownedLists {
          items {
            id
            title
            groups
            createdAt
            updatedAt
            owner {
              id
              subID
              name
              createdAt
              updatedAt
            }
            items {
              nextToken
            }
            users {
              nextToken
            }
          }
          nextToken
        }
        lists {
          items {
            id
            userID
            listID
            createdAt
            updatedAt
            user {
              id
              subID
              name
              createdAt
              updatedAt
            }
            list {
              id
              title
              groups
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      items {
        items {
          id
          item
          category
          split
          listItemListId
          createdAt
          updatedAt
          list {
            id
            title
            groups
            createdAt
            updatedAt
            owner {
              id
              subID
              name
              createdAt
              updatedAt
            }
            items {
              nextToken
            }
            users {
              nextToken
            }
          }
        }
        nextToken
      }
      users {
        items {
          id
          userID
          listID
          createdAt
          updatedAt
          user {
            id
            subID
            name
            createdAt
            updatedAt
            ownedLists {
              nextToken
            }
            lists {
              nextToken
            }
          }
          list {
            id
            title
            groups
            createdAt
            updatedAt
            owner {
              id
              subID
              name
              createdAt
              updatedAt
            }
            items {
              nextToken
            }
            users {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const createListItem = /* GraphQL */ `
  mutation CreateListItem(
    $input: CreateListItemInput!
    $condition: ModelListItemConditionInput
  ) {
    createListItem(input: $input, condition: $condition) {
      id
      item
      category
      split
      listItemListId
      createdAt
      updatedAt
      list {
        id
        title
        groups
        createdAt
        updatedAt
        owner {
          id
          subID
          name
          createdAt
          updatedAt
          ownedLists {
            items {
              id
              title
              groups
              createdAt
              updatedAt
            }
            nextToken
          }
          lists {
            items {
              id
              userID
              listID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        items {
          items {
            id
            item
            category
            split
            listItemListId
            createdAt
            updatedAt
            list {
              id
              title
              groups
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        users {
          items {
            id
            userID
            listID
            createdAt
            updatedAt
            user {
              id
              subID
              name
              createdAt
              updatedAt
            }
            list {
              id
              title
              groups
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateListItem = /* GraphQL */ `
  mutation UpdateListItem(
    $input: UpdateListItemInput!
    $condition: ModelListItemConditionInput
  ) {
    updateListItem(input: $input, condition: $condition) {
      id
      item
      category
      split
      listItemListId
      createdAt
      updatedAt
      list {
        id
        title
        groups
        createdAt
        updatedAt
        owner {
          id
          subID
          name
          createdAt
          updatedAt
          ownedLists {
            items {
              id
              title
              groups
              createdAt
              updatedAt
            }
            nextToken
          }
          lists {
            items {
              id
              userID
              listID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        items {
          items {
            id
            item
            category
            split
            listItemListId
            createdAt
            updatedAt
            list {
              id
              title
              groups
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        users {
          items {
            id
            userID
            listID
            createdAt
            updatedAt
            user {
              id
              subID
              name
              createdAt
              updatedAt
            }
            list {
              id
              title
              groups
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteListItem = /* GraphQL */ `
  mutation DeleteListItem(
    $input: DeleteListItemInput!
    $condition: ModelListItemConditionInput
  ) {
    deleteListItem(input: $input, condition: $condition) {
      id
      item
      category
      split
      listItemListId
      createdAt
      updatedAt
      list {
        id
        title
        groups
        createdAt
        updatedAt
        owner {
          id
          subID
          name
          createdAt
          updatedAt
          ownedLists {
            items {
              id
              title
              groups
              createdAt
              updatedAt
            }
            nextToken
          }
          lists {
            items {
              id
              userID
              listID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        items {
          items {
            id
            item
            category
            split
            listItemListId
            createdAt
            updatedAt
            list {
              id
              title
              groups
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        users {
          items {
            id
            userID
            listID
            createdAt
            updatedAt
            user {
              id
              subID
              name
              createdAt
              updatedAt
            }
            list {
              id
              title
              groups
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const createUserList = /* GraphQL */ `
  mutation CreateUserList(
    $input: CreateUserListInput!
    $condition: ModelUserListConditionInput
  ) {
    createUserList(input: $input, condition: $condition) {
      id
      userID
      listID
      createdAt
      updatedAt
      user {
        id
        subID
        name
        createdAt
        updatedAt
        ownedLists {
          items {
            id
            title
            groups
            createdAt
            updatedAt
            owner {
              id
              subID
              name
              createdAt
              updatedAt
            }
            items {
              nextToken
            }
            users {
              nextToken
            }
          }
          nextToken
        }
        lists {
          items {
            id
            userID
            listID
            createdAt
            updatedAt
            user {
              id
              subID
              name
              createdAt
              updatedAt
            }
            list {
              id
              title
              groups
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      list {
        id
        title
        groups
        createdAt
        updatedAt
        owner {
          id
          subID
          name
          createdAt
          updatedAt
          ownedLists {
            items {
              id
              title
              groups
              createdAt
              updatedAt
            }
            nextToken
          }
          lists {
            items {
              id
              userID
              listID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        items {
          items {
            id
            item
            category
            split
            listItemListId
            createdAt
            updatedAt
            list {
              id
              title
              groups
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        users {
          items {
            id
            userID
            listID
            createdAt
            updatedAt
            user {
              id
              subID
              name
              createdAt
              updatedAt
            }
            list {
              id
              title
              groups
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateUserList = /* GraphQL */ `
  mutation UpdateUserList(
    $input: UpdateUserListInput!
    $condition: ModelUserListConditionInput
  ) {
    updateUserList(input: $input, condition: $condition) {
      id
      userID
      listID
      createdAt
      updatedAt
      user {
        id
        subID
        name
        createdAt
        updatedAt
        ownedLists {
          items {
            id
            title
            groups
            createdAt
            updatedAt
            owner {
              id
              subID
              name
              createdAt
              updatedAt
            }
            items {
              nextToken
            }
            users {
              nextToken
            }
          }
          nextToken
        }
        lists {
          items {
            id
            userID
            listID
            createdAt
            updatedAt
            user {
              id
              subID
              name
              createdAt
              updatedAt
            }
            list {
              id
              title
              groups
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      list {
        id
        title
        groups
        createdAt
        updatedAt
        owner {
          id
          subID
          name
          createdAt
          updatedAt
          ownedLists {
            items {
              id
              title
              groups
              createdAt
              updatedAt
            }
            nextToken
          }
          lists {
            items {
              id
              userID
              listID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        items {
          items {
            id
            item
            category
            split
            listItemListId
            createdAt
            updatedAt
            list {
              id
              title
              groups
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        users {
          items {
            id
            userID
            listID
            createdAt
            updatedAt
            user {
              id
              subID
              name
              createdAt
              updatedAt
            }
            list {
              id
              title
              groups
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteUserList = /* GraphQL */ `
  mutation DeleteUserList(
    $input: DeleteUserListInput!
    $condition: ModelUserListConditionInput
  ) {
    deleteUserList(input: $input, condition: $condition) {
      id
      userID
      listID
      createdAt
      updatedAt
      user {
        id
        subID
        name
        createdAt
        updatedAt
        ownedLists {
          items {
            id
            title
            groups
            createdAt
            updatedAt
            owner {
              id
              subID
              name
              createdAt
              updatedAt
            }
            items {
              nextToken
            }
            users {
              nextToken
            }
          }
          nextToken
        }
        lists {
          items {
            id
            userID
            listID
            createdAt
            updatedAt
            user {
              id
              subID
              name
              createdAt
              updatedAt
            }
            list {
              id
              title
              groups
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      list {
        id
        title
        groups
        createdAt
        updatedAt
        owner {
          id
          subID
          name
          createdAt
          updatedAt
          ownedLists {
            items {
              id
              title
              groups
              createdAt
              updatedAt
            }
            nextToken
          }
          lists {
            items {
              id
              userID
              listID
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        items {
          items {
            id
            item
            category
            split
            listItemListId
            createdAt
            updatedAt
            list {
              id
              title
              groups
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        users {
          items {
            id
            userID
            listID
            createdAt
            updatedAt
            user {
              id
              subID
              name
              createdAt
              updatedAt
            }
            list {
              id
              title
              groups
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
